import {
  ScmAuth,
  //scmAuthApiRef,
  ScmIntegrationsApi,
  scmIntegrationsApiRef
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  //bitbucketAuthApiRef,
  createApiFactory,
  configApiRef
} from '@backstage/core-plugin-api';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),

  // createApiFactory({
  //   api: scmAuthApiRef,
  //   deps: { 
  //     bitbucketAuthApi: bitbucketAuthApiRef 
  //   },
  //   factory: ({ bitbucketAuthApi }) => 
  //     ScmAuth.merge(
  //       ScmAuth.forBitbucket(bitbucketAuthApi),
  //     )
  // }),
  ScmAuth.createDefaultApiFactory(),
];
